<template>
  <s-autocomplete
    v-model="leakProfile"
    :items="allItems"
    item-value="id"
    item-title="name"
    label="Leak Profile"
    :return-object="true"
    :clearable="false"
    :hide-no-data="false"
    spellcheck="false"
    v-bind="$attrs"
  >
    <template #append-item v-if="allItems.length > 0">
      <page-loader :items="fetchItems" />
    </template>
    <template #no-data v-if="allItems.length === 0 && fetchItems.hasNextPage.value">
      <page-loader :items="fetchItems" />
    </template>
  </s-autocomplete>
</template>

<script setup lang="ts">
import type { LeakProfile } from '~/utils/types';

const leakProfile = defineModel<LeakProfile|null>();

const fetchItems = useSearchableCursorPaginationFetcher<LeakProfile>({
  baseURL: '/api/v1/leakprofiles/',
  query: {
    ordering: 'name,created',
  }
});

useLazyAsyncData(async () => {
  if (!leakProfile.value) {
    await fetchItems.fetchNextPage();
  }
});

const allItems = computed(() => {
  const out = [...fetchItems.data.value];
  if (leakProfile.value && !out.some(lp => lp.id === leakProfile.value?.id)) {
    out.unshift(leakProfile.value);
  }
  return out;
});

defineExpose({
  leakprofiles: allItems,
})
</script>
